import React from "react";

const Header1 = ({ children, className }) => (
    <h2
        className={`${className} text-outragous-orange text-3xl md:text-4xl xl:text-5xl 2xl:text-5xl font-normal leading-10`}
    >
        {children}
    </h2>
);

export default Header1;
